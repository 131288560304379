<template>
  <div class="auth-wrapper auth-v2 h-100">
    <b-row class="no-gutters auth-inner m-0">
      <!-- Login-->
      <b-col
        lg="5"
        class="d-none d-lg-flex align-items-center pm-login-bg "
      >
        <div class="login-bg">
          <div class="w-100 h-100 position-relative">
            <component
              :is="$route.meta.authImage"
              class="position-absolute position-bottom-0 w-100"
            />
          </div>
        </div>
      </b-col>
      <!-- /Login-->
      <!-- Right Text-->
      <b-col
        lg="6"
        class="
          d-flex
          flex-column
          justify-content-between
          align-items-center
          auth-bg
          px-0
        "
      >
        <div class="w-100 px-0">
          <b-col
            sm="8"
            md="9"
            lg="9"
            class="
              px-0
              mx-auto
              d-flex
              align-items-center
              justify-content-between
              px-sm-20
            "
          >
            <logo-light />
            <div class="ml-2 mt-3">
              <wameed-menu
                id="dropdown-1"
                classes=" text-reg-14"
                field-classes="mb-0"
                value-classes="text-reg-14 text-font-secondary"
                :title="orderBy.name"
                prepend-icon="lang-icon"
                variant="no-color"
                :items="orderByItems"
                text="name"
                active-item="1"
                @dropdownClick="changeLang"
              />
            </div>
          </b-col>
        </div>
        <b-col class="d-flex align-items-start flex-1 px-0">
          <b-col
            sm="8"
            md="9"
            lg="9"
            class="px-0 mx-auto flex-1 px-sm-20 mt-layout-content"
          >
            <router-view />
          </b-col>
        </b-col>
      </b-col>
    </b-row>
    <loading />
  </div>
</template>

<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import Loading from "@/components/loading.vue";
import WameedMenu from "@/components/WameedMenu.vue";
import WameedBtn from "@/components/WameedBtn.vue";

export default {
  components: {
    LayoutFull,
    Loading,
    WameedMenu,
    WameedBtn,
  },
  data: () => ({
    orderBy: {
      name: "عربي",
      id: "ar",
    },
    orderByItems: [
      {
        name: "عربي",
        id: "ar",
      },
      {
        name: "English",
        id: "en",
      },
    ],
  }),
  created() {
    this.orderBy = this.$router.currentRoute.params.lang == "ar"
        ? this.orderByItems[0]
        : this.orderByItems[1];
  },
  methods: {
    goToMain() {
      this.$router.push("/");
    },
    changeLang(item) {
      this.orderBy = item;

      this.$router.push({
        name: this.$router.currentRoute.name,
        params: { lang: item.id },
      });
      this.$router.go();
    },
  },
};
</script>
